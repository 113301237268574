import {React, useState, useEffect} from "react";
import HomeFooter from "./HomeFooter";
import engineer from "../assets/images/festival2.jpg";
import {Helmet} from "react-helmet";
import style from "../assets/css/style.css";



function LazyBackgroundImageKnowledge({img}) {
  const [loaded, setLoaded] = useState(false);
  const handleLoad = () => {
    setLoaded(true);
  };
return (
 <div className="h-3/4"
    style={{
      backgroundImage: `url("${img}")`,
      backgroundRepeat:"no-repeat", backgroundSize:"cover", backgroundPosition:"center",
      filter: loaded ? "none" : "blur(20px)",
      transition: "filter 0.5s",
    }}>
    <img src={img} alt="" onLoad={handleLoad} style={{ display: "none" }} />
    {loaded}

    <div className="md:container md:mx-auto justify-center flex justify-center items-center px-auto" >
      <div className="flex flex-col justify-center items-start row-start-2 sm:row-start-1 py-12 px-6">
        <br /><br /><br />
        <h1 className="text-3xl lg:text-4xl xl:text-5xl font-medium text-white leading-normal">
        <br />  <strong></strong>
        </h1><br /><br />
        <p className="text-xl text-white mt-4 mb-6">
          <br /> <br /><br />
        </p>  
        </div>
          <div className="flex w-full">
            <div className="h-full w-full" >
            </div>
          </div>
    </div>
 </div>
);
}

function TextHeader(){
  return(
    <header className="w-full container mx-auto">
          <br /><br />
   <div className="flex flex-col items-center py-6">
    <h1>
    <a className="font-bold text-sky-600 uppercase hover:text-sky-900 text-3xl" href="/">
                Special Gift Ideas
            </a>
    </h1>

            <h2 className="text-lg text-base text-black-500 dark:text-white text-sm font-semibold italic">
                Special <span className="text-orange-400">gifts </span>on special
                 <span className="text-sky-600"> occasions</span> for special 
                <span className="text-orange-400"> people</span>.
            </h2>

    </div>
    </header>
  );
}


function ArticleCard ({id, title, category, date, monthyear, coverimage, shortdescription}){
  return (
      <>
      <div className="sm:w-1/2  mb-10 px-4 flex justify-center items-center">
      <section className="blog_section layout_padding">
        <div className="container">
           {/**<div className="row"> */}
            {/**<div className="col-md-6"> */}
              <div className="box bg-gray-200 dark:bg-gray-700">
                <div className="img-box  bg-white dark:bg-gray-700">
                  {/**rounded-t-lg */}
                  <img
                  className=""
                    src={coverimage}
                    alt=""
                  />
                  <h4 className="blog_date">
                  {date} <br />
                  {monthyear}
                  </h4>
                </div>
                <div className="detail-box bg-gray-200 dark:bg-gray-700 text-base text-black-500 dark:text-white">
                <p className="text-blue-400 font-semibold text-sm">🔹 {category}</p>
                  <h5>{title}</h5>
                  <p>{shortdescription}</p>
                  <a className="flex justify-center items-center" href={"/read/"+id} key={id}>Read more</a> 
                </div>
              </div>
            {/**</div> */}
          {/** </div>*/}
        </div>
      </section>
    </div>
      </>
  )
}





function HomeCards() {
  //const [searchItem, setSearchItem] = useState('')

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    const url = `https://goldfish-app-asx3v.ondigitalocean.app/gift/latest`;
    fetch(url)
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )

  }, [])

  if (error) {
    return <div className="px-12 py-12 flex justify-center items-center dark:text-white">Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div className="px-12 py-12 flex justify-center items-center dark:text-white">Loading...</div>;
  } else {
  return (
      <>
      <section className="body-font">
          <div className="container px-5 py-12 mx-auto">
            <h2 className="flex justify-center items-center px-auto text-3xl font-bold text-black-500 dark:text-white">LATEST ARTICLES</h2>
            <div className="py-2  px-2 flex flex-wrap justify-center items-center">

                <a href="/state" className="btn btn-error btn-sm m-1">State holidays</a>
                <a href="/christian" className="btn btn-primary btn-sm m-1">Christian festivals</a>
                <a href="/muslim" className="btn btn-success btn-sm m-1">Muslim festivals</a>
                <a href="/hindu" className="btn btn-secondary btn-sm m-1">Hindu festivals</a>
                <a href="/buddhist" className="btn btn-success btn-sm m-1">Buddhist festivals</a>
                <a href="/jewish" className="btn btn-primary btn-sm m-1">Jewish festivals</a>
                <a href="/observance" className="btn btn-secondary btn-sm m-1">Observances</a>
                <a href="/other" className="btn btn-error btn-sm m-1">Other</a>
            </div>
              <div className=""  style={{margin:"0 auto", padding:"10px", maxWidth:"1200px"}}>
                  <div className="px-2 py-2">
                      <div className="flex flex-wrap -mx-4 -mb-10">
                          {items.map(article => <ArticleCard 
                              key={article.Id} 
                              id={article.Id} 
                              title={article.Title} 
                              category= {article.Category} 
                              coverimage={article.CoverImageUrl} 
                              date={article.Date}
                              monthyear={article.MonthAndYear} 
                              >

                              </ArticleCard>
                          )}
                      </div>
                  </div>

              </div>
          </div>
      </section> 


      </>
  );}

}


function HomeDescription(){
  return (
    <>
<div className="text-base-content  pt-2  flex justify-center items-center  bg-base-100 mainDiv">
  <div className="container px-2 mx-auto py-2 lg:py-4">
    <div className="text-base leading-7 text-black-500 dark:text-white px-6 lg:px-12">
      <h2 className="flex justify-center items-center sm:text-2xl text-xl m-4 pb-6 text-purple-500 ">
        <b>Why should we celebrate life's special moments ?</b>
      </h2>

      <p> 
      A holiday is more than just a marked date on the calendar – it's a pause in our daily routine, 
      a moment set aside for celebration, remembrance, or honoring significant events and people. 
      These special days weave the fabric of our shared cultural experience, bringing communities together 
      and creating lasting memories.
      </p>
            <h2 className="flex justify-center items-center sm:text-2xl text-xl m-4 pb-6 text-purple-500 ">
        <b>The First American Holiday</b>
      </h2>

      <p> 
      Thanksgiving holds the distinction of being America's first officially proclaimed holiday. 
      In 1789, President George Washington declared November 26 as a day of national thanksgiving, 
      though the tradition traces back to the celebrated 1621 feast shared between Plymouth colonists and Wampanoag Native Americans. 
      This established a precedent for national days of celebration and gratitude that continues to shape our holiday traditions.
      </p>

      <h2 className="flex justify-center items-center sm:text-2xl text-xl m-4 pb-6 text-purple-500 ">
        <b>Why Holidays Matter</b>
      </h2>

      <p> 
      Holidays serve essential functions in our society: <br />

      &bull; Cultural Preservation: They help pass down traditions and values across generations <br />
      &bull; Community Building: They bring people together, strengthening social bonds <br />
      &bull; Mental Well-being: They provide breaks from routine and opportunities for joy <br />
      &bull; Historical Connection: They help us remember and honor significant events <br />
      &bull; Personal Growth: They offer moments for reflection and renewal
      </p>
      <h2 className="flex justify-center items-center sm:text-2xl text-xl m-4 pb-6 text-purple-500 ">
        <b>The Art of Holiday Gift-Giving</b>
      </h2>

      <p> 
      Gift-giving during holidays is a beautiful tradition that transcends cultures and time. This meaningful practice:
      </p>
      <h3 className="flex justify-center items-center font-bold sm:text-xl text-lg m-4 pb-6 text-blue-500 ">
      ◼ Strengthens Relationships
      </h3>
      <p>
      &bull; Creates lasting bonds between people <br />
      &bull; Shows thoughtfulness and appreciation<br />
      &bull; Builds shared memories and traditions<br />
      &bull; Expresses love and care without words<br />
      </p>
      <h3 className="flex justify-center items-center font-bold sm:text-xl text-lg m-4 pb-6 text-blue-500 ">
      ◼ Benefits Both Giver and Receiver
      </h3>
      <p>
      &bull; Triggers release of feel-good hormones <br />
      &bull; Increases feelings of gratitude <br />
      &bull; Enhances social connections <br />
      &bull; Creates moments of pure joy <br />
      </p>
      <h3 className="flex justify-center items-center font-bold sm:text-xl text-lg m-4 pb-6 text-blue-500 ">
      ◼ Teaches Important Values
      </h3>
      <p>
      &bull; Generosity and sharing<br />
      &bull; Thoughtfulness and consideration<br />
      &bull; Gratitude and appreciation<br />
      &bull; The joy of giving over receiving<br />
      </p>


      <h2 className="flex justify-center items-center sm:text-2xl text-xl m-4 pb-6 text-purple-500 ">
        <b>Making Gift-Giving Meaningful</b>
      </h2>

      <p> 
      The perfect holiday gift isn't about price – it's about: <br />

&bull; Understanding the recipient's interests and needs<br />
&bull; Choosing something that shows thoughtfulness<br />
&bull; Creating or selecting items with personal significance<br />
&bull; Presenting the gift in a meaningful way
      </p>

      <h2 className="flex justify-center items-center sm:text-2xl text-xl m-4 pb-6 text-purple-500 ">
        <b>Beyond Traditional Holidays</b>
      </h2>

      <p> 
      While major holidays like Christmas and Hanukkah are well-known for gift-giving, 
      many other celebrations offer opportunities for meaningful exchange:<br />

&bull; National holidays and observances <br />
&bull; Cultural and religious festivals<br />
&bull; Personal milestones and achievements<br />
&bull; State-specific celebrations       
      </p>

      <h2 className="flex justify-center items-center sm:text-2xl text-xl m-4 pb-6 text-purple-500 ">
        <b>Why Choose Thoughtful Gifts?</b>
      </h2>

      <p> 
      In our fast-paced digital world, taking time to select meaningful gifts matters more than ever. Thoughtful gifts: <br />

      &bull; Create lasting impressions<br />
      &bull; Show genuine appreciation<br />
      &bull; Build stronger relationships<br />
      &bull; Make celebrations more memorable
      </p>

      <h2 className="flex justify-center items-center sm:text-2xl text-xl m-4 pb-6 text-purple-500 ">
        <b>Your Guide to Perfect Gift-Giving</b>
      </h2>

      <p> 
      Whether you're celebrating a major holiday or a special state observance, 
      finding the right gift can transform an ordinary moment into an unforgettable memory. 
      Let us help you discover perfect gift ideas that capture the spirit of every celebration and bring joy to your loved ones.
      Start exploring our curated gift suggestions for every holiday and special occasion. 
      Because every celebration deserves the perfect gift to make it memorable.
      </p>
    

    </div>



  </div>
</div>


    </>
  )
}




function Home () {
  return(
    <>
            <TextHeader></TextHeader>

        <Helmet>
            <meta charSet="utf-8"/>
            <title>Special Gift Ideas Home page</title>
            <link rel="canonical" href="https://specialgiftideas.net/"/>
            <meta name="description"
                  content="Learn about festivals, holidays, observances and some gift ideas for your friends, relatives, and family members on these occasions."/>
            <meta name="keywords" content="holidays,festivals,gift ideas"/>
            <meta property="og:title" content="Special Gift Ideas Home page"/>
            <meta property="og:description"
                  content="Learn about festivals, holidays, observances and some gift ideas for your friends, relatives, and family members on these occasions."/>
            <meta property="og:site_name" content="Special Gift Ideas Home page"/>
            <meta property="og:locale" content="en_US"/>
            <meta property="og:type" content="article"/>
        </Helmet>

        <LazyBackgroundImageKnowledge img={engineer}></LazyBackgroundImageKnowledge>
        <HomeCards></HomeCards>

        <HomeDescription   />

        <HomeFooter></HomeFooter>
    </>
  )
}
export default Home;